import BuyerRecap from "./BuyerRecap";
import { useContext, useState } from "react";
import IBuyerCoverage from "./serverModel/IBuyerCoverage";
import { Axios, AxiosResponse } from "axios";
import GlobalMessageContext from "../../context/GlobalMessageContext";
import Modal from 'factor-lib/Modal';
import Loader from 'factor-lib/Loader';
import IUrlWithContentType from 'factor-lib/forms/Upload/IUrlWithContentType';
import AxiosContext from "../../context/AxiosContext";
import errorHandlerMessageHandler from 'factor-lib/serverUtils/errorHandlerMessageHandler';
import IBuyerInvoice from "./serverModel/IBuyerInvoice";

interface IInvoicePdfShow {
    isLoading: boolean;
    urlWithContentType: IUrlWithContentType | null; // when loaded
}

const BuyerRecapLoaded = (
    {
        displayFullError,
        buyerCoverage
    }: {
        displayFullError: boolean;
        buyerCoverage: IBuyerCoverage;
    }
) => {
    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    const [invoicePdfShowO, setInvoicePdfShowO] = useState<IInvoicePdfShow | null>(null);
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;
    return (
        <div>
            <BuyerRecap buyerName={buyerCoverage.buyerName}
                        buyerId={buyerCoverage.buyerId}
                        seller={buyerCoverage.seller}
                        invoices={buyerCoverage.invoices}
                        showInvoicePdf={async (invoice: IBuyerInvoice) => {
                            setInvoicePdfShowO({
                                isLoading: true,
                                urlWithContentType: null
                            });
                            await axios.get<IUrlWithContentType>(
                                `/invoice/${invoice.id}/pdf-url`
                            )
                                .finally(() => setInvoicePdfShowO({
                                    ...invoicePdfShowO!,
                                    isLoading: false
                                }))
                                .then((response: AxiosResponse<IUrlWithContentType>) => setInvoicePdfShowO({
                                    ...invoicePdfShowO!,
                                    urlWithContentType: response.data
                                }))
                                .catch((e: Error) =>
                                    // httpClient.errorHandler(messageHandler, e)
                                    errorHandlerMessageHandler(messageHandler, displayFullError, e)
                                );
                        }} />

            { !!invoicePdfShowO &&
                <Modal className='full-height'
                       id='invoiceDetailsModal'
                       maxWidth={null}
                       active={true}
                       fullMaxWidth={false}
                       close={() => setInvoicePdfShowO(null)}>
                    { invoicePdfShowO.isLoading
                        ? <Loader />
                        : !!invoicePdfShowO.urlWithContentType &&
                            <object data={invoicePdfShowO.urlWithContentType.url}
                                    type={invoicePdfShowO.urlWithContentType.contentType}
                                    className='p-full-container'>
                                {/* TODO: Alternative text */}
                            </object>
                    }
                </Modal>
            }
        </div>
    );
}

export default BuyerRecapLoaded;
