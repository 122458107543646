import {Outlet, useParams} from "react-router-dom";
import {createBrowserRouter} from "react-router-dom";
import BuyerRecap from "./Pages/Main/BuyerRecap";
import {useContext} from "react";
import LoggerContext from "factor-lib/services/logger/LoggerContext";
import CurrentEnv from "./env/CurrentEnv";
import AxiosContext from "./context/AxiosContext";
import BuyerRecapPage from "./Pages/Main/BuyerRecapPage";
import buildAxios from 'factor-lib/serverUtils/buildAxios';
import {FunctionBackendTimeoutMilliseconds} from "factor-lib/serverUtils/axiosConfigUtils";
import ILogger from "factor-lib/services/logger/ILogger";
import ErrorHandler from "./ErrorHandler";
import IBuyerRecapProps from "./Pages/Main/IBuyerRecapProps";
import {ROUTE_BUYER_RECAP_URL} from "./Pages/Main/urls";
import testBuyerRecapProps, {testBuyerRecapPath} from "./testBuyerRecapProps";

const buyerTokenParamName = 'buyerToken';

const BuyerRecapPageWrapper = () => {
    const logger: ILogger = useContext<ILogger | undefined>(LoggerContext)!;
    return (
        // <BuyerRecapPage buyerToken={useParams()[buyerTokenParamName]!}/>;
        <AxiosContext.Provider value={buildAxios(
            CurrentEnv.backendUrl,
            logger,
            FunctionBackendTimeoutMilliseconds,
            {
                BuyerToken: useParams()[buyerTokenParamName]!
            }
        )}>
            <BuyerRecapPage displayFullError={CurrentEnv.debug}/>
        </AxiosContext.Provider>
    );
}

const router = (storyBookUI: boolean) => {

    // TODO : error handling

    // TODO : hierarchy, especially when we have multiple Pages

    let routes = [
        {
            path: `${ROUTE_BUYER_RECAP_URL}/:${buyerTokenParamName}`,
            element: <BuyerRecapPageWrapper/>
        }
    ];

    // TODO : remove at some point, use a proper storybook component
    if (storyBookUI) {
        const props: IBuyerRecapProps = testBuyerRecapProps;
        // TODO : can we do reconstruct
        routes = [
            ...routes,
            {
                path: testBuyerRecapPath,
                element: <BuyerRecap buyerId={props.buyerId}
                                     invoices={props.invoices}
                                     buyerName={props.buyerName}
                                     seller={props.seller}
                                     showInvoicePdf={() => {}} />
            }
        ];
    }
    return createBrowserRouter([
        {
            path: '/',
            element: <Outlet/>,
            errorElement: <ErrorHandler/>,
            children: routes
        }
    ]);
}

export default router;