import {getEnv, ensureNonNullEnvName, envError} from 'factor-lib/utils/envUtils';

import DevConfig from "./DevConfig";
import DemoConfig from "./DemoConfig";
import ProdConfig from "./ProdConfig";
import IBuyerEnvConfig from "./IBuyerEnvConfig";
import IBuyerEnv from "./IBuyerEnv";
import LocalConfig from "./LocalConfig";

// TODO : move to lib at some point ?
// const envNameWrapper: {
//     envName: string | null;
// } = {
//     envName: null
// };

const currentConfigO: IBuyerEnvConfig | null =
    getEnv<IBuyerEnvConfig | null>(
        () => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME),
        LocalConfig,
        DevConfig,
        DemoConfig,
        ProdConfig
    );

const CurrentConfigEnv: IBuyerEnvConfig = !!currentConfigO ? currentConfigO : envError();

const CurrentEnv: IBuyerEnv = ({
    ...CurrentConfigEnv,
    cookiesManager: CurrentConfigEnv.cookiesManager(),
    logger: CurrentConfigEnv.logger()
});

export default CurrentEnv;
    // getEnv(() => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME), LocalConfig, null /* DevConfig */, null /* DemoConfig */, null/* ProdConfig */);
