import demoConfigFactory from "factor-lib/envs/demoConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";

const DemoConfig: IBuyerEnvConfig = {
    ...demoConfigFactory(
        'f49d1954-b23c-4ff1-baaa-13cd2803c5ad',
        'recap.sandbox.dimpl.io' // 'agreeable-mud-07f6fa203.1.azurestaticapps.net' // Do not include http / https
    ),
    backendUrl: 'https://factor-demo-buyers-function.azurewebsites.net',
    debug: false
};

export default DemoConfig;
