export default interface IBuyerInvoice {
    id: string;
    number: string;
    amountWoTax: number;
    amountTax: number;
    // ideally null, but null + 1000 = null in LINQ
    partialAmountPaidByBuyer: number;
    completePaid: boolean;
    // TODO : careful ! I changed the serialization
    dueDate: string; // Serialization (json does not know date)
}

export const getAmountLeftToPay = (invoice: IBuyerInvoice): number =>
    invoice.amountTax + invoice.amountWoTax - invoice.partialAmountPaidByBuyer;