import ISeller from "../serverModel/ISeller";
import SellerSummary from "./SellerSummary";
import { formatAmount } from "factor-lib/utils/amountUtils";
import IBuyerInvoice, { getAmountLeftToPay } from '../serverModel/IBuyerInvoice';
import { sum } from 'factor-lib/utils/utils';
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";

import './coveragesummary.scss';

const invoicesAmountLeftToPay = (
    invoices: IBuyerInvoice[],
    dueDateInFuture: boolean
) => {
    const today = new Date();

    const dueDateFilter: (i: IBuyerInvoice) => boolean =
        dueDateInFuture
            ? (i) => serverDateDeserialization(i.dueDate) > today
            : (i) => serverDateDeserialization(i.dueDate) <= today;

    return sum(
        invoices.filter((i) => !i.completePaid && dueDateFilter(i)).map(getAmountLeftToPay)
    );
}
    
const CoverageSummary = (
    {
        className,
        seller,
        invoices
    }: {
        className?: string,
        seller: ISeller,
        invoices: IBuyerInvoice[],
    }
) => {

    return (
        <div className={`${!!className ? `${className} ` : ''}coverage p-bg-white p-padding-4`}>
            <div className='about-seller p-margin-bottom-5'>
                <h1 className='p-cs-title'>Informations sur votre fournisseur</h1>
                <SellerSummary seller={seller} />
            </div>

            <div className='summaries'>
                <div className='p-horizontal-container'>
                    <span>Somme due</span>
                    <span className={ /* warning plutot ? */'p-ok-text p-bold'} style={{fontSize: '1.5rem'}}>
                        { formatAmount(invoicesAmountLeftToPay(invoices, false)) }
                    </span>
                </div>

                <hr className='divider p-margin-top-6 p-margin-bottom-6'/>

                <div className='p-horizontal-container'>
                    <span>Somme à devoir bientôt</span>
                    <span className='p-bold'>
                        { formatAmount(invoicesAmountLeftToPay(invoices, true)) }
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CoverageSummary;
