import IBuyerInvoice from "../serverModel/IBuyerInvoice";
import BuyerInvoiceCardContent from "./BuyerInvoiceCardContent";

const BuyerInvoicePaidCard = (
    {
        invoicePaid,
        showPdf
    }: {
        invoicePaid: IBuyerInvoice;
        showPdf: () => void;
    }
) =>
    <BuyerInvoiceCardContent className='box'
                             invoice={invoicePaid}
                             showPdf={showPdf}
                             displayFullAmount={true} />;

export default BuyerInvoicePaidCard;
