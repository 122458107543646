import {useQuery} from "@tanstack/react-query";
import {ReactElement} from "react";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";

const ReactQueryWrapper = <T, > (
    {
        queryKey,
        queryFunc,
        onLoading,
        onError,
        onSuccess,
        displayFullError
    }: {
        queryKey: string[];
        queryFunc: (signal?: AbortSignal) => Promise<T>;
        onLoading: () => ReactElement;
        onError?: (e: string) => ReactElement;
        onSuccess: (t: T) => ReactElement;
        displayFullError: boolean;
    }
) =>
    <ReactQueryResultWrapper result={useQuery<T>(queryKey, ({ signal }) => queryFunc(signal)) /* this useQuery() does not work if in lib (https://github.com/TanStack/query/discussions/4302) */}
                             onLoading={onLoading}
                             onError={onError}
                             onSuccess={onSuccess}
                             displayFullError={displayFullError} />;

export default ReactQueryWrapper;
