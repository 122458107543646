import {isMobileDeviceWindow} from "factor-lib/utils/deviceUtils";
import IIntercom from "factor-lib/services/Intercom/IIntercom";
import IUserCookiesChoices from "factor-lib/services/cookies/IUserCookiesChoices";
import ILogger from "factor-lib/services/logger/ILogger";
import {SeverityLevel} from "@microsoft/applicationinsights-web";

const handleCookies2 = (
    logger: ILogger,
    // cookiesManager: ICookiesManager,
    cookiesManager: (onAccept: ((choices: IUserCookiesChoices) => void)) => void,
    // segmentFactory: () => Promise<ISegment>,
    intercomFactory: () => Promise<IIntercom>
): void => {
    // const cookieHandler: CookieHandler = new CookieHandler(async () => {
    //     const segment = await segmentFactory();
    //     segment.init();
    //     return segment;
    // });

    // most cookies are not used. Axeptio does not accept different cookies settings on same domain
    // cookiesManager(
    //     async () => (await intercomFactory()).init(null),
    //     () => segmentCookieHandler.acceptCookie(),
    //     // tslint:disable-next-line:no-empty
    //     () => { }
    // );

    cookiesManager((choices: IUserCookiesChoices): void => {
        logger.trackTrace(SeverityLevel.Information, "cookies are accepted by user" /* either accepted cookies, either right now, or before */, choices);
        if (choices.intercom) {
            // Fork
            (async (): Promise<void> => {
                (await intercomFactory()).init(null);
            })();
        }
        // if (choices.segment) {
        //     segmentCookieHandler.acceptCookie();
        // }
    });

    // return segmentCookieHandler;
};

// also setup the cookies
const handleCookies = (
    logger: ILogger,
    cookiesManager: (onAccept: ((choices: any) => void)) => void,
    // segmentFactory: () => Promise<ISegment>,
    intercomFactory: () => Promise<IIntercom>
): void => {
    // don't show messenger BUYER_RECAP page, if run on the mobile
    // if so, we don't need cookies at all
    if (!isMobileDeviceWindow()) {
        handleCookies2(
            logger,
            cookiesManager,
            // segmentFactory,
            intercomFactory
        );
    }
}


export default handleCookies;
