import { formatAmount } from "factor-lib/utils/amountUtils";
import IBuyerInvoice from "../serverModel/IBuyerInvoice";
import BuyerInvoiceCardContent from "./BuyerInvoiceCardContent";

const BuyerInvoiceDueCard = (
    {
        // key,
        invoiceDue,
        showPdf
    }: {
        // key?: string;
        invoiceDue: IBuyerInvoice;
        showPdf: () => void;
    }
) =>
    invoiceDue.partialAmountPaidByBuyer > 0
        ? <div className='box' style={{padding: 0}}>
            <div className='p-padding-6 p-warn-border p-border-big p-warn-header p-size-75 is-marginless'
                 style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                 }}>
                <div className='p-horizontal-center'>
                    <div className='p-icon p-warn-border p-warn-icon'>!</div>
                    <div className='p-margin-left-6'>Montant initial de la facture: </div>
                    <div className='p-margin-left-7 p-warn-icon'>
                        { formatAmount(invoiceDue.amountWoTax + invoiceDue.amountTax/* , invoice.currency */) }
                    </div>
                </div>
            </div>
            <BuyerInvoiceCardContent className='p-padding-5'
                                     invoice={invoiceDue}
                                     showPdf={showPdf}
                                     displayFullAmount={false} />
        </div> : <BuyerInvoiceCardContent className='box'
                                          invoice={invoiceDue}
                                          showPdf={showPdf}
                                          displayFullAmount={false} />;

export default BuyerInvoiceDueCard;
