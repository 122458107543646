import ISeller from "../serverModel/ISeller";
import Link from "factor-lib/Link";
import {copyIban} from "factor-lib/forms/Inputs/ibanUtils";

import './sellersummary.scss';

const ibanElementName = 'iban';

const SellerSummary = (
    {
        seller

    }: {
        seller: ISeller;
    }
) =>
    <div className='seller-summary'>
        <table>
            <tbody>
                <tr>
                    <th>Nom</th>
                    <td>{ seller.companyName }</td>
                </tr>
                { !!seller.iban &&
                    <tr>
                        <th>IBAN</th>
                        <td>
                            <span className='iban p-margin-right-6'
                                  id={ibanElementName}>
                                { seller.iban }
                            </span>
                            <div>
                                <Link id='copyIban'
                                      action={{clickHandler: () => {
                                              copyIban(document.getElementById(ibanElementName)!);
                                          }}}
                                      text='Copier' />
                            </div>
                        </td>
                    </tr>
                }
                <tr>
                    <th>Contact</th>
                    <td>
                        <div>{ seller.phone }</div>
                        <div>{ seller.email }</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>;

export default SellerSummary;
