import IBuyerCoverage from "./serverModel/IBuyerCoverage";
import BuyerRecapLoaded from "./BuyerRecapLoaded";
import Loader from "factor-lib/Loader";
import {useContext} from "react";
import GlobalMessageContext from "../../context/GlobalMessageContext";
import errorHandlerMessageHandler from "factor-lib/serverUtils/errorHandlerMessageHandler";
import AxiosContext from "../../context/AxiosContext";
import ReactQueryWrapper from "../../Utils/ReactQueryWrapper";
import slowLoadingMessage from 'factor-lib/serverUtils/slowLoadingMessage';
import axios, {Axios} from "axios";


const getActionAsync = async (
    axiosInstance: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    signal?: AbortSignal
): Promise<IBuyerCoverage> => {
    try {
        const r = axiosInstance.get<IBuyerCoverage>(
            '/recap',
            {
                signal
            }
        );
        const res = await r;
        return res.data;
    } catch (e: unknown) {
        // For some weird reasons, some initial queries are cancel locally.
        if (!axios.isCancel(e)) {
            errorHandlerMessageHandler(messageHandler, displayFullError, e);
            // httpClient.errorHandler(messageHandler, e);
        }
        throw e; // Why throw here, and not in bankin ?
    }
}

const BuyerRecapPage = (
    {
        displayFullError
    }: {
        displayFullError: boolean;
    }
) => {
    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;

    return (
        <ReactQueryWrapper queryKey={['buyerRecap']}
                           displayFullError={displayFullError}
                           queryFunc={(signal?: AbortSignal) =>
                               getActionAsync(axios, messageHandler, displayFullError, signal)
                           }
                           onLoading={() =>
                               <div className='p-padding-4 p-vertical-center'>
                                   <span className='p-margin-bottom-4'>{ slowLoadingMessage }</span>
                                   <Loader />
                               </div>
                           }
                           onSuccess={(buyerCoverage: IBuyerCoverage) =>
                               <BuyerRecapLoaded displayFullError={displayFullError}
                                                 buyerCoverage={buyerCoverage} />
                           } />
    );
};

export default BuyerRecapPage;
