import {useRouteError} from "react-router-dom";
import {useContext, useEffect} from "react";
import {SeverityLevel} from "@microsoft/applicationinsights-web";
import ILogger from "factor-lib/services/logger/ILogger";
import LoggerContext from "factor-lib/services/logger/LoggerContext";
import CurrentEnv from "./env/CurrentEnv";

const ErrorHandler = () => {
    // error here will be 'swallowed' and not reach 'root', which would be handled by telemetry js
    const routeError: any = useRouteError();
    const error: Error | undefined = routeError instanceof Error ? routeError : routeError.error as Error | undefined /* react-router specific, for invalid url for example */;
    const errorMessage = routeError.message ?? routeError.data /* react-router specific, for invalid url for example */;
    const logger: ILogger = useContext<ILogger | undefined>(LoggerContext)!;
    useEffect(() => {
        if (!!error) {
            logger.trackException(error);
        } else {
            logger.trackTrace(SeverityLevel.Error, errorMessage);
        }
    }, [logger, error, errorMessage]);
    return (
        <div className='p-padding-2'>
            <div className='p-vertical-center'>
                <div className='p-bold'>Une erreur est survenue</div>
                { CurrentEnv.debug &&
                    <div className='p-size-7 p-padding-top-7'>{ errorMessage }</div>
                }
            </div>
        </div>
    )
};

export default ErrorHandler;