import devConfigFactory from "factor-lib/envs/devConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";

const DevConfig: IBuyerEnvConfig = {
    ...devConfigFactory(
        '3e0f271b-1986-4cbf-8fd7-821e2802ba8c',
        'jolly-ocean-0a3184803.azurestaticapps.net' // Do not include http / https
    ),
    backendUrl: 'https://factor-dev-buyers-function-2.azurewebsites.net',
    debug: true
};

export default DevConfig;
