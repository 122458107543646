import { BUYER_INVOICE_LIST_ID, BuyerRecapFCId } from './BuyerRecapConsts';
import TopBar from 'factor-lib/TopBar/TopBar';
import CoverageSummary from './CoverageSummary/CoverageSummary';
import { ReactComponent as AssuranceFacturesAssurees } from './AssuranceFacturesAssurees.svg';
import ISeller from "./serverModel/ISeller";
import IBuyerInvoice from "./serverModel/IBuyerInvoice";
import BuyerInvoiceDueCard from "./BuyerInvoiceCard/BuyerInvoiceDueCard";
import Link from 'factor-lib/Link';
import { comparingDate, serverDateDeserialization } from 'factor-lib/utils/dateUtils';
import BuyerInvoicePaidCard from './BuyerInvoiceCard/BuyerInvoicePaidCard';

import './buyerrecap.scss';


const navigateToDimplMain = (buyerId: string) =>
    window.location.assign(`https://dimpl.io/?utm_source=buyer_recap&utm_campaign=${buyerId}`);

const dueDateComparator = comparingDate((i: IBuyerInvoice) => serverDateDeserialization(i.dueDate));

const BuyerRecap = (
    {
        buyerId,
        seller,
        buyerName,
        invoices,
        showInvoicePdf
    }: {
        buyerId: string,
        seller: ISeller,
        buyerName: string,
        invoices: IBuyerInvoice[],
        showInvoicePdf: (invoice: IBuyerInvoice) => void,
    }
) => {
    const invoicesCompletePaid = invoices.filter((i) => i.completePaid);
    const invoicesDue = invoices.filter((i) => !i.completePaid);

    return (
        <div id={BuyerRecapFCId} className='full-container'>
            <TopBar logoClick={() => navigateToDimplMain(buyerId)}
                    style={{marginBottom: '0 !important'}}
                    leftExtra={null}
                    title={null}
                    rightMenu={
                        <div className='p-margin-right-3'>
                            Vous souhaitez en savoir plus sur Dimpl ?
                            <Link id='DimplMainLink'
                                  className='p-margin-left-7'
                                  text='On vous explique'
                                  action={{clickHandler: () => navigateToDimplMain(buyerId)}} />
                        </div>
                    } />

            <div className='p-background'>
                <div className='container'>
                    <div className='p-padding-4'>
                        <div className='has-text-centered has-text-left-desktop p-margin-top-1-desktop p-margin-bottom-3'>
                            <h1 className='is-size-4 p-margin-bottom-5'>
                                Bonjour {buyerName},
                            </h1>
                            <p>
                                Voici ce que vous devez à {seller.companyName} :
                            </p>
                        </div>

                        <div className='columns is-variable is-8 is-desktop'>
                            <div id={BUYER_INVOICE_LIST_ID} className='column'>
                                { invoicesDue.length === 0
                                    ? <div className='has-text-centered p-margin-top-2'>
                                        <div className='nothing-text p-margin-bottom-4'>
                                            Vous êtes à jour de vos paiements.
                                        </div>
                                        <div className='nothing-text p-margin-bottom-4'>
                                            Merci !
                                        </div>
                                        <div>
                                            <AssuranceFacturesAssurees />
                                        </div>
                                    </div>
                                    : invoicesDue
                                        .sort(dueDateComparator)
                                        .map((invoice, invoiceIndex) =>
                                            <BuyerInvoiceDueCard key={`i-${invoiceIndex}-${invoice.number}`}
                                                                 invoiceDue={invoice}
                                                                 showPdf={() => showInvoicePdf(invoice)} />
                                        )
                                }

                                { invoicesCompletePaid.length > 0 &&
                                    <div>
                                        <hr className='p-margin-top-3 p-margin-bottom-3' />
                                        <p className='has-text-centered has-text-left-desktop p-margin-bottom-5'>
                                            Factures déjà réglées
                                        </p>
                                        { invoicesCompletePaid
                                            .sort(dueDateComparator)
                                            .map((invoice, invoiceIndex) =>
                                                <BuyerInvoicePaidCard key={`i-${invoiceIndex}-${invoice.number}`}
                                                                      invoicePaid={invoice}
                                                                      showPdf={() => showInvoicePdf(invoice)} />
                                            )
                                        }
                                    </div>
                                }
                            </div>

                            <div className='column'>
                                <CoverageSummary className='is-fixed-mobile'
                                                 seller={seller}
                                                 invoices={invoices} />
                                {/* TODO : I forgot what this is for <div style={{ height: `${ this.footerOffset }px` }}*/}
                                {/*     class='footerOffset' />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyerRecap;
