import React from 'react';
import reportWebVitals from './reportWebVitals';

import currentEnv from "./env/CurrentEnv";
import LoggerContext from 'factor-lib/services/logger/LoggerContext';
import GlobalMessageWrapper from "./context/GlobalMessageWrapper";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import {createRoot} from "react-dom/client";

import handleCookies from "./services/handleCookies";
import RouterWrapper from "./RouterWrapper";

// After other imports, otherwise does not work
import './index.scss';

// ensureIsProdNonDebug(config.debug, envName);

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        staleTime: 10 /* in minutes */ * 60_000,
        // retry: false
        // refetchOnWindowFocus: false
    },
});

const { logger } = currentEnv;

handleCookies(
    logger,
    currentEnv.cookiesManager,
    // currentEnv.segment,
    currentEnv.intercom
);

createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <GlobalMessageWrapper>
                <LoggerContext.Provider value={logger}>
                    <QueryClientProvider client={queryClient}>
                        <RouterWrapper/>
                        {/*<BrowserRouter>*/}
                        {/*    <BuyerRoutes storyBookUI={currentEnv.storyBookUI}/>*/}
                        {/*</BrowserRouter>*/}
                        { currentEnv.debug &&
                            <ReactQueryDevtools initialIsOpen={false} />
                        }
                    </QueryClientProvider>
                </LoggerContext.Provider>
            </GlobalMessageWrapper>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
