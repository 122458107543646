import OkCancelRow from 'factor-lib/Modals/OkCancelRow';
import {ChangeEvent, ReactElement, useContext, useState} from "react";
import Link from 'factor-lib/Link';
import TitledModal from 'factor-lib/Modals/TitledModal';
import DateInput, {isValidDateInput, parseDateInput} from 'factor-lib/forms/DateInput/DateInput';
import InputAmount, {isValidAmount, parseInputAmount} from 'factor-lib/forms/Inputs/InputAmount';
import {isValidString} from 'factor-lib/forms/Inputs/utils';
import Input from 'factor-lib/forms/Inputs/Input';
import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {Axios} from "axios";
import AxiosContext from "../../../context/AxiosContext";
import TextOnlyModal from 'factor-lib/Modals/TextOnlyModal';

const Field = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <div className='field'>
        <div className='p-margin-bottom-7 p-form-label'>
            { title }
        </div>
        { children }
    </div>

interface IPaymentDeclarationRequest {
    amount: number;
    date: Date;
    label?: string; // Libelle
    comment?: string;
}

const DeclarePaymentModalContent = (
    {
        invoiceId,
        complete,
        cancel
    }: {
        invoiceId: string;
        complete: () => void;
        cancel: () => void;
    }
) => {
    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    const [ amountInput, setAmountInput ] = useState('');
    const [ dateInput, setDateInput ] = useState('');
    const [ labelInput, setLabelInput ] = useState('');
    const [ commentInput, setCommentInput ] = useState('');

    const declarePaymentMutation: UseMutationResult<void, any, IPaymentDeclarationRequest> =
        useMutation<void, any, IPaymentDeclarationRequest>(
            (params2) =>
                axios.post<IPaymentDeclarationRequest, void>(
                    `/invoice/${invoiceId}/payment`,
                      params2
                ),
            ({
                onSuccess: () => {
                    complete();
                }
            })
        );

    return (
        <div className='p-padding-4'>
            <div>
                <Field title='Montant'>
                    <InputAmount fieldName='Montant'
                                 inputAmountValue={amountInput}
                                 enabled={{
                                     updateInputValue: setAmountInput,
                                     innerId: {
                                         value: 'inputAmountId',
                                         autofocus: true
                                     }
                                 }}/>
                </Field>

                <Field title='Date'>
                    <DateInput dateInputValue={dateInput}
                               enabled={{
                                   updateDateInputValue: setDateInput,
                                   validateDate: () => null
                               }} />
                </Field>

                <Field title='Libellé du paiement (optionnel)'>
                    <Input fieldName='Libellé'
                           inputValue={labelInput}
                           enabled={{
                               updateInputValue: setLabelInput,
                               maxLength: 128,
                               validateInput: () => null
                           }} />
                </Field>

                <Field title='Commentaire (optionnel)'>
                    <textarea className='textarea'
                              placeholder='Commentaire'
                              value={commentInput}
                              maxLength={256}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentInput(e.target.value)} />
                </Field>
            </div>
            <OkCancelRow className='p-margin-top-4'
                         okText='Signaler'
                         validate={(isValidAmount(amountInput) && isValidDateInput(dateInput)) ? (() => declarePaymentMutation.mutate({
                             amount: parseInputAmount(amountInput),
                             date: parseDateInput(dateInput)!,
                             // skip empty optional strings
                             label: isValidString(labelInput) ? labelInput : undefined,
                             comment: isValidString(commentInput) ? commentInput : undefined
                         })) : null}
                         isLoading={declarePaymentMutation.isLoading}
                         cancel={cancel}/>
        </div>
    );
}

interface IComplete {
    isComplete: boolean;
}

const DeclarePayment = (
    {
        className,
        invoiceId
    }: {
        className?: string;
        invoiceId: string;
    }
) => {
    const [showModal, setShowModal] = useState<IComplete | null>(null);

    return (
        <div className={className}>
            <Link text='Signaler un paiement'
                  action={{
                      clickHandler: () => setShowModal({ isComplete: false })
                  }} />

            { !!showModal
                ? showModal.isComplete
                    ? <TextOnlyModal close={() => setShowModal(null)}
                                     maxWidth='640px'
                                     fullMaxWidth={false}
                                     message='Merci, nos équipes opérationnelles vont prendre en compte votre déclaration et mettre à jour votre récapitulatif'
                                     buttonsProps={{
                                         actionO: () => setShowModal(null),
                                         isLoading: false,
                                         text: 'Ok'
                                     }}/>
                    : <TitledModal id='unused'
                                   title='Signalez un paiement que vous avez déjà effectué'
                                   close={() => setShowModal(null)}>
                        <DeclarePaymentModalContent invoiceId={invoiceId}
                                                    complete={() => setShowModal({isComplete: true})}
                                                    cancel={() => setShowModal(null)}/>
                    </TitledModal>
                : null
            }
        </div>
    );
}

export default DeclarePayment;