import prodConfigFactory from "factor-lib/envs/prodConfigFactory";
import IBuyerEnvConfig from "./IBuyerEnvConfig";

const ProdConfig: IBuyerEnvConfig = ({
    ...prodConfigFactory(
        '9e498e54-d0b4-4454-a36e-c8d2e2b71980',
        // 'polite-tree-05e416103.1.azurestaticapps.net' // Do not include http / https
    ),
    backendUrl: 'https://api.recap.dimpl.io', // 'https://factor-prod-buyers-function.azurewebsites.net'
    debug: false
});

export default ProdConfig;
