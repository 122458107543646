
import LocalConfigLib from "factor-lib/envs/LocalConfig";
import IBuyerEnvConfig from "./IBuyerEnvConfig";

const LocalConfig: IBuyerEnvConfig = ({
    ...LocalConfigLib,
    backendUrl: 'http://localhost:7071',
    debug: true,
    // cookiesManager:
        // () => Axeptio('localhost')
        // DevConfig.cookiesManager
})

export default LocalConfig;